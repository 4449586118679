
import Vue from "vue";

export default Vue.extend({
  name: "Discover",
  data() {
    return {};
  },
  methods: {}
});
